import React, { useEffect } from 'react';
import './Spb.css';

import { useDispatch, useSelector } from 'react-redux';
import { pathSpb, pathVideo } from 'config';
// import { getPerson } from 'store/ducks/person/actions';

import { MoreArrow } from 'components';
import { spbSelector } from 'store/ducks/homepage/selectors/spbSelector';
// import { personsSelector } from 'store/ducks/homepage/selectors/personsSelector';

export function Spb() {
    const dispatch = useDispatch();

    let spbPosts = useSelector(spbSelector);
    const { status } = useSelector(
        (state: {
            homepage: { status: string; statusMaterialsLoadMore: string };
        }) => state.homepage
    );

    // const personList = useSelector(personsSelector);
    // const status = useSelector(
    //   (state: { person: { status: string } }) => state.person.status
    // );

    // useEffect(() => {
    //   if (status == 'IDLE') {
    //     dispatch(getPerson());
    //   }
    // }, []);

    return (
        <section className="video">
            <h2 className="page-title video__title">Цифровой Петербург</h2>
            <MoreArrow type="video" link={pathSpb}>
                Все материалы
            </MoreArrow>
            {spbPosts &&
                spbPosts.length > 0 &&
                spbPosts.map(item => {
                    const { id, link, img, tag, title, type, isShowTag, date, exclusive_material } = item;
                    return (
                        <article className="card card--m" key={id}>
                            <a className="materials__link" href={link}>
                                <img
                                    src={img}
                                    alt={title}
                                    className="materials__img"
                                />
                                <div className='materials__common'>
                                    <h3
                                        className="materials__title"
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    ></h3>
                                    <div className="materials__date">{date}</div>
                                </div>
                            </a>
                            {exclusive_material && <div className="tag exclusive">Эксклюзив</div>}
                        </article>
                    );
                })}
        </section>
    );
}
