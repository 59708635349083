import { createSelector } from 'reselect';

import { Post, PostMetaItem, PostTaxonomyItem } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const spb = (state: { homepage: { spb: Post[] } }) => state.homepage.spb;

export const spbSelector = createSelector([spb], data => {
    let arraySelector: any[] = [];

    if (data && data.length) {
        data.map((item: Post, index: number) => {
            if (item) {
                let dateArticle = item.created_at;
                if (item.publication_at) {
                    dateArticle = item.publication_at;
                }

                let object = {
                    id: item.id,
                    link: item.categories_path + '/' + item.slug,
                    img: getPreview(
                        item.get_post_types,
                        item.announce_img,
                        null,
                        true,
                        moment(dateArticle)
                    ),
                    date: moment(dateArticle).format('DD.MM.YYYY'),
                    title: item.title,
                    exclusive_material: item.exclusive_material,
                };

                arraySelector.push(object);
            }
        });
    }

    return arraySelector;
});
